<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white mt-3"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Reports
      </h6>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="dashboard"
          nameRoute="Dashboard"
          navText="Dashboard"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="pills"
          nameRoute="Pills"
          navText="Content Pills"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">pie_chart</i>
            
          </template>
        </sidenav-collapse>
      </li>
      
      
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="push_batches"
          nameRoute="PushBatchesInfo"
          navText="Invitation Batches"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">info</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="depositions"
          nameRoute="Depositions"
          navText="Depositions"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">star</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="list_reports"          
          nameRoute="ListReports"
          navText="DataBase Files"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">bar_chart</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="content_tree"          
          nameRoute="ListContentTree"
          navText="Content Tree"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">account_tree</i>
          </template>
        </sidenav-collapse>
      </li>


      <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white mt-3"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Adm Projects
      </h6>

     
      <li class="nav-item">
        <sidenav-collapse
          url=""
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="projects"
          nameRoute="Projects"
          navText="Projects"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">wysiwyg</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="participants"
          nameRoute="BatchParticipants"
          navText="Participants">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">group_add</i>
          </template>
        </sidenav-collapse>
      </li>


      
      <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white mt-3"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          System Control
      </h6>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="list_users"
          nameRoute="ListUsers"
          navText="User Control"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">account_circle</i>
          </template>
        </sidenav-collapse>
      </li>
 





      <!-- <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="profile"
          navText="Profile"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="sign-in"
          navText="SignIn"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">login</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="sign-up"
          navText="SignUp"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5 notranslate">assignment</i>
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>

  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
  },
};
</script>
