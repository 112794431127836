<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 pb-2">
            <div
              class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
              <h6 class="col text-white text-capitalize ps-3">Content Tree</h6>
            </div>       
         
            <Loading :message="loading_message" :loading="loading"/>

            <!-- Select for courses -->
            <div v-if="loading == false" class="pb-2 pt-0 mt-0 d-flex justify-content-between align-items-center mb-4" style="margin-left: 20px; margin-right: 20px;">
              <div class="col-5">
                <select class="form-select form-select-lg" v-model="project" @change="selectedProject">
                  <option v-if="projects == null" value="">Loading ... </option>
                  <option v-else value="">Projects</option>
                  <option v-for="project in projects" :value="project.project_id" :key="project.project_id">{{ project.project_name }}</option>
                </select>
              </div>

              <div v-if="this.project != '' && error != true">
                <button class="btn bg-gradient-info text-w font-weight-bold btn-md d-flex justify-content-between align-items-center" @click="downloadLogContentTree">
                  <i class="material-icons-round opacity-10 fs-5 notranslate">cloud_download</i>&nbsp; Logs Content Tree
                </button>
              </div>
            </div>

            <!-- Token not found -->
            <div class="col-12 d-flex justify-content-center" v-if="(Array.isArray(returnAPI.contents) == false && showMessage == true && loading == false && this.project != '')">
              <div id="not_found_token" class="col-4 ps-3 alert alert-danger fw-bold" v-if="error == true">
                Sorry, token not found.
              </div>

              <div id="not_found" class="col-4 ps-3 alert alert-warning fw-bold" v-else>
                Databases files not found.
              </div>
            </div>

            <div id="content" v-else-if="Array.isArray(returnAPI.contents) && loading == false && this.project != ''">             
              <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-danger" v-if = "returnAPI.contents.length == 0 && showMessage == true"> There are no contents. </div>

              <ContentTreeTable :returnAPI="returnAPI"/>              
            </div>
          </div>

          <LastUpdateInfo :key="lastUpdateKey" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTreeTable from "@/views/components/ContentTreeTable.vue";
import Loading from "@/views/components/Loading.vue";
import {http} from "../http/index";
import LastUpdateInfo from "./components/LastUpdateInfo.vue";

export default {
  name: "ContentTree",
  
  components : {
    Loading,
    ContentTreeTable,
    LastUpdateInfo,
  },

  data() {        
    return {
      error: false,
      loading: true,
      lastUpdateKey: 0,
      showMessage : true,
      token: localStorage.getItem("token"),
      loading_message: "",
      
      project: '',
      projects : null,
      projectId : null,
      selectedProj: true,

      returnAPI: [],
    };
  },

  mounted(){
    this.getAllProjects()
  },

  methods: {
    searchContents(){
      this.showMessage = false
      this.loading = true
      this.loading_message = "Loading your content tree"

      var payload = {
        token_user: this.token,
        company_id: this.project
      }

      http.post("/api/v1/dashboard/report-logs-for-access-command-tree/", payload)
      .then((response) => {
        this.returnAPI = response.data        
      })
      .catch(() => {        
        this.returnAPI = false
        this.loading = false
        this.error = true
      })
      .finally(() => {
        if (!Array.isArray(this.returnAPI.contents)) {
          this.showMessage = true
        }
        
        this.loading = false
      })
    },

    getAllProjects(){
      this.loading = true
      this.loading_message = "Loading your projects"
      let payload = {
        token: this.token
      }
      
      http.post('/api/v1/dashboard/list-projects-by-client/', payload)
      .then((response) => {
        this.projects = response.data.projects
      })
      .finally(() => {
        this.loading = false;
      });
    },

    selectedProject(){      
      if (this.projectId !== null) {
        this.project = this.projectId
      }
      
      this.searchContents()
      this.lastUpdateKey += 1
    },    

    downloadLogContentTree(){
      this.loading = true;
      this.loading_message = "Downloading logs content tree";

      var payload = {
        token_user: this.token,
        company_id: this.project
      }
      
      http.post("api/v1/dashboard/download-CSV-logs-custom-command/", payload)      
      .then((response) => {
          let data = response.data;
          let blob = new Blob([data], { type: 'application/csv' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `logs-content-tree.csv`;
          link.click();
          this.loading = false;
      })
    },
  }
};
</script>

<style scoped>

#total{
  font-size: 16px;
  color: white;
  text-align: center;
}

#loading{
  padding-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}

#not_found{
  color: white;
  text-align: center;
}

#content{
  padding-top: 5px;
  font-size: 16px;
  color: #424242;
}

</style>
