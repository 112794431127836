<template>

    <div class="table-responsive align-items-center mb-0" style = "margin: 0px 20px;">

    <table class="table-responsive table table-dordered table-hover">
        <thead class="bg-info opacity-7 text-white fw-bold">
          <tr>
              <th class="text-center px-0">Tree</th>
              <th class="text-center">Number of queries</th>
              <th class="text-center">Access %</th>
          </tr>
        </thead>

        <tbody>        
            <tr v-for="content in returnAPI.contents" :key="content.index">
                <td class="text-center align-middle text-dark" wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">{{content.command_tag}}</td>
                <td class="text-center align-middle text-dark">{{content.access_count}}</td>
                <td class="text-center align-middle fw-bold" :style="{ backgroundColor: getBackgroundColor(content.access_count, this.returnAPI.total_access) }" :class="{'text-white': isHighPercentage(content.access_count, returnAPI.total_access), 'text-dark': isLowPercentage(content.access_count, returnAPI.total_access)}">{{ Math.round((content.access_count / this.returnAPI.total_access) * 100) }}</td>
            </tr>
            <tr style="background-color: gray;">
                <td class="text-center align-middle fw-bold text-white" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">Total geral</td>
                <td class="text-center align-middle fw-bold text-white">{{this.returnAPI.total_access}}</td>
                <td class="text-center align-middle fw-bold text-white">100%</td>
            </tr>

        </tbody>
      </table>
    
    </div>

</template>

<script>

import MaterialButton from "@/components/MaterialButton.vue";

    export default {
    name: "ContentTreeTable",

    components : {
        MaterialButton 
    },

    methods : {        
        getBackgroundColor(accessCount, totalAccess) {
            const percentage = ((accessCount / totalAccess) * 100) + 15;
            const alphaDecimal = Math.round(percentage * 2.55);
            const alphaHex = alphaDecimal.toString(16).padStart(2, '0').toUpperCase();
            return `#439df0${alphaHex}`;
        },

        isHighPercentage(accessCount, totalAccess) {
            const percentage = ((accessCount / totalAccess) * 100) + 15;
            return percentage >= 50;
        },

        isLowPercentage(accessCount, totalAccess) {
            const percentage = ((accessCount / totalAccess) * 100) + 15;
            return percentage < 50;
        },

        formateDate(date){
            const dataObj = new Date(date);
            const year = dataObj.getUTCFullYear();
            const month = dataObj.getUTCMonth() + 1; 
            const day = dataObj.getUTCDate();
            const hour = dataObj.getUTCHours();
            const minutes = dataObj.getUTCMinutes();

            if (day < 10) {
                if (month < 10) {
                    return `0${day}/0${month}/${year} ${hour}:${minutes}`;
                } else {
                    return `0${day}/${month}/${year} ${hour}:${minutes}`;
                }
            } else if (month < 10) {
                return `${day}/0${month}/${year} ${hour}:${minutes}`;
            }

            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
    },

    props: {
        returnAPI: {
            type: Array,
            required: true
        }
    }
}
</script>
